/* here you can put your own css to customize and override the theme */
table.dataTable thead th, table.dataTable td{padding:10px;vertical-align: middle}
.dataTables_wrapper table.dataTable {border-collapse: collapse;}
.logo a {
  font-size: 40px;
  color: #0986BD;
  text-transform: uppercase;
}
.page-header.navbar .page-logo .logo-default {
  margin: 10px 0 0 0;
  font-size: 21px;
  line-height: normal;
  color: #0986BD;
  text-transform: uppercase;
}
.logo a:hover,
.page-header.navbar .page-logo .logo-default:hover {
  text-decoration: none
}
@media (max-width: 767px ) {
  .portlet.light {
    padding-left: 0;
    padding-right: 0;
  }
  .portlet.light .form .form-body{padding-top:0}
}
.form-group.form-md-line-input .form-control~.form-control-focus:after, .form-group.form-md-line-input .form-control~label:after {
  content: '';
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 50%;
  height: 2px;
  width: 0;
  visibility: hidden;
  transition: .2s ease all;
}
.form-group.form-md-line-input .form-control.edited:not([readonly])~.form-control-focus:after, .form-group.form-md-line-input .form-control.edited:not([readonly])~label:after, .form-group.form-md-line-input .form-control:focus:not([readonly])~.form-control-focus:after, .form-group.form-md-line-input .form-control:focus:not([readonly])~label:after {
  visibility: visible;
  left: 0;
  width: 100%;
  background: #0986BD;
}
.login .content .form-control:focus, .login .content .form-control:active,
.form-group.form-md-line-input.has-success .form-control {
  border-bottom: 1px solid #0986BD;
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
table.dataTable thead th, table.dataTable td{padding:10px;vertical-align: middle}
.dataTables_wrapper table.dataTable {border-collapse: collapse;}
.logo a {
  font-size: 40px;
  color: #0986BD;
  text-transform: uppercase;
}
.form-group.form-md-line-input .form-control~.form-control-focus:after, .form-group.form-md-line-input .form-control~label:after {
  content: '';
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 50%;
  height: 2px;
  width: 0;
  visibility: hidden;
  transition: .2s ease all;
}
.form-group.form-md-line-input .form-control.edited:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input .form-control.edited:not([readonly])~label:after,
.form-group.form-md-line-input .form-control:focus:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input .form-control:focus:not([readonly])~label:after {
  visibility: visible;
  left: 0;
  width: 100%;
  background: #0986BD;
}
.login .content .form-group.form-md-line-input .form-control:focus,
.login .content .form-group.form-md-line-input .form-control:active,
.form-group.form-md-line-input.has-success .form-control {
  border-bottom: 1px solid #0986BD;
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.login .content .login-form .form-group.form-md-line-input>.input-icon>i{
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  bottom: auto;
  height: auto;
  color: #8290a3;
}
.login .content .login-form .form-group.form-md-line-input.has-success .form-control:focus:not([readonly])~i{
  color: #0986BD;
}
.form-group.form-md-line-input>.input-icon>label {
  display: block;font-size: 0;
}
.login .form-group.form-md-line-input.has-success {
  padding: 0;
  margin-bottom: 15px;
}
.marginb0{margin-bottom: 0 !important;}

.tab-pane-actions .btn-group a.btn,
button.btn.btn-danger,
button.btn.btn-primary,
button.btn.btn-success,
button.btn.grey-steel,
a.btn:not(.default) {
  padding: 4px 10px;
  font-size: 15px;
}
.box-footer.tab-pane-footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e7ecf1;
}
.portlet.light{ padding: 12px 15px 15px;}
.portlet.light > form .mt-checkbox>span{
  right: -20px;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
}
.portlet.light .mt-checkbox-list {
  float: left;
  width: 100%;
  padding: 5px 0;
}
.fixedwidth{
  width: 55px;
  padding: 3px 5px;
}
.coursefixedwidth{
  width: 55px;
  padding: 3px 5px;
  margin: 3px;
}

.portlet.light .mt-checkbox-list label.control-label.mt-checkbox.mt-checkbox-outline {
  padding-top: 0;    text-align: left;
}
.portlet.light .multiselect-parent.btn-group.dropdown-multiselect span.caret {
  margin-left: 7px;
}
.portlet.light .portlet.inner .portlet-body {
  padding:15px;
}
.portlet.light .portlet-body img {
  max-width: 100%;
}
.portlet.light .portlet.inner .portlet-body > .form-group:last-child {
  margin-bottom: 0;
}
.portlet.light .portlet.inner .portlet-body label.mt-checkbox.mt-checkbox-outline {
  margin-bottom: 0;
  line-height: 24px;
}
.portlet.light #session_management .portlet.inner .portlet-body label.mt-checkbox.mt-checkbox-outline {
  line-height: 19px;
}
.inner.portlet .row {
  margin-right: -15px;
  margin-left: -15px;
}
th > .mt-checkbox.mt-checkbox-single {
  padding: 0;
  display: block;
  height: 18px;
  width: 18px;
}
.dataTables_wrapper.form-inline.no-footer table.dataTable.autoCheckbx td:first-child, .dataTables_wrapper.form-inline.no-footer table.dataTable.autoCheckbx th:first-child {
  width: 40px !important;
  max-width: 40px;
}
td > .mt-checkbox.mt-checkbox-single, th > .mt-checkbox.mt-checkbox-single {
  margin: 0 auto;
  display: block;
  height: 18px;
  width: 18px;
  right: 0;
  padding: 0;
}

.nav-tabs.nav-justified>li {
  display: block;
  width: auto;
  float: left;
}
div#innermenu nav-topmenu {
  float: right;
}
.btn i {
  font-style: normal;
}
.tab-pane-actions {
  float:left;
  width: 100%
}
.tab-pane-actions .btn-group a.btn {
  width: auto;
  margin-right: 5px;
}
.tab-pane h4.page-header.ng-binding {
  margin-top: 20px;
}
.dataTables_wrapper .dataTables_filter input[type="search"] {
  margin-right: 0;
}
.tabbable-custom .tabbable-custom {
  margin: 0;
}
.portlet>.portlet-title .course-view .nav-tabs {
  background: 0 0;
  margin: 1px 0 0;
  float: right;
  display: inline-block;
  border: 0;
}
.portlet.light>.portlet-title .course-view .nav-tabs>li {
  margin: 0;
  padding: 0;
}
.portlet.light>.portlet-title .course-view .nav-tabs>li>a {
  margin: 0;
  padding: 12px 13px 13px;
  font-size: 13px;
  color: #666;
}
table.table.view-course-table td .pull-left {
  width: 100%
}
table.table.view-course-table td,
table.table.view-course-table tr {
  padding-left: 0;
  padding-right: 0;
  float:left;width:50%
}
table.table.view-course-table td:first-child label {
  font-weight: bold;
}
table.table.view-course-table td small.control-label {
  margin: 0 !important;
  vertical-align: top;
  line-height: normal;
  display: block;
  border-radius: 0;
  width: auto;
}
.noborder{border:0 !important;}
.page-content-white .page-content .page-bar {
  margin-bottom: 15px;
}

table.table.view-course-table td label.control-label {
  margin-top: 0;
}
table.table.view-course-table td {
  padding-top: 10px;
  padding-bottom: 10px;
}
div#innermenu.nested nav-topmenu {
  float: none;
}
.portlet.box.grey-ddd{
  border: 1px solid #ddd;
}
div#innermenu.nested nav.topnav-menu.tabbable-custom {
  margin-bottom: -1px;
}
.portlet.box.grey-ddd .portlet-body > .row > .col-md-12 > .nav-tabs-custom  > .col-md-3,
.form-body.view-user-info .row .col-md-8 {
  padding-left: 0;
}
.form-body.view-user-info .row .col-md-4 {
  padding-right: 0;
}
.portlet.box.grey-ddd .portlet-body > .row {
  margin: 0;
}
.form-body.view-user-info .row {
  margin: 0 0 15px;
}
.portlet.box.grey-ddd .portlet-body > .row > .col-md-12,
.portlet.box.grey-ddd .portlet-body > .row > .col-md-12 > .nav-tabs-custom  > .col-md-9 {
  padding: 0;
}
.form-body.view-user-info .row label.control-label {
  padding: 0;
  margin: 0;
  font-weight: 600;text-align: left;
}
.form-body.view-user-info .form-group {
  float: left;
  width: 100%;
  margin: 10px 0;
}
.form-body.view-user-info .form-group p.form-control-static {
  padding: 0;min-height:1px;
}
.form .mt-checkbox-list.single label.mt-checkbox {
  margin: 0;
}
.form-horizontal .form-group .mt-checkbox-list.single {
  padding: 5px 0;
}
.tabbable-custom .portlet {
  margin: 0;
}
.portlet.box.green>.portlet-body,
.tabbable-custom .portlet.box.green>.portlet-body {
  padding: 10px 10px 5px;
}
.portlet.box.green .portlet-body .box-footer {
  margin-bottom: 10px;
}
.portlet>.portlet-title>.nav-tabs>li.has-error-class a,
.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label {
  color: red !important;
}
.has-error .form-control:focus,
.has-error .form-control{
  border-color: red ;
}
.form-group.row.instructor-row.ng-scope {
  margin: 10px 0;
}
.inpCheckbox .checkbox input[type=checkbox] {
  margin: 0 5px 0 0;
  position: relative;
  top: 4px;
  height: 15px;
  width: 15px;
}

.table>tbody>tr>td,
.inbox .table th{vertical-align:middle}
.table-responsive > br{display: none}
.ngdialog-content table.table {
  margin-bottom: 0;
}
.ngdialog.ngdialog-theme-default .ngdialog-close:before{color: #555;}
tags-input#emailgroup .tags .tag-item{background: #ddd;}
.inbox .inbox-nav>li.active>a {
  border-left: 4px solid #3598dc;
}
.tab-content.student-contract .dt-buttons {
  margin: 15px 0;
}
.tab-content.student-contract .dataTables_filter {
  clear: both;
  float: none;
  display: block;
}
.tab-content.student-contract .portlet .dataTables_wrapper .dt-buttons {
  position: absolute;
  margin: 0;
}
.ngdialog.ngdialog-theme-default .ngdialog-content{
  max-height: 100%;
  overflow-y: auto;
}
a.btn.sms-send-compose-btn,
.mail-read a.btn,
.inbox a.btn.compose-btn {
  padding: 6px 12px;
  font-size: 14px;
}
.thumb {top:0 !important;margin:0 !important}
.website-pmc ul.dropdown-menu.dropdown-menu-form {
  height: auto !important;
}
.website-pmc ul.dropdown-menu.dropdown-menu-form input.checkboxInput[type=checkbox] {
  margin: 3px 0 0;
}
.website-pmc ul.dropdown-menu.dropdown-menu-form .checkbox span {
  padding-left: 9px;
}

.dropdown-menu>li>a.option{
  padding: 5px 25px;
}
.dataTables_wrapper table.dataTable th,
.dataTables_wrapper table.dataTable td{
  width: 1%;
  min-width: 1%;
}
.dataTables_wrapper table.dataTable th:last-child, .dataTables_wrapper table.dataTable td:last-child {
  white-space: nowrap;
}

.modal-body .checkbox input[type="checkbox"] {
  margin: 0;
  left: 0;
  top: 5px;
}

.modal-body .checkbox label {
  padding-left: 20px;
  position: relative;
}
.subcourse-list-sharing-options i.glyphicon.glyphicon-share-alt,
i.subcourse_share.glyphicon.glyphicon-share-alt {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
  font-size: 12px;
  color: #fff;
  vertical-align: middle;
  margin-left: 5px;
  background: #f0ad4d;
  height: 27px;
  width: 34px;
  text-align: center;
  line-height: 27px;
  top: 0;
  box-sizing: border-box;
  border: 1px solid #eea236;
  position: relative;
}

.subcourse-list-sharing-options i.fa.fa-file-text:before,
i.fa.fa-file-text.subcourse_file:before,
.subcourse-list-sharing-options i.glyphicon.glyphicon-share-alt:before,
i.subcourse_share.glyphicon.glyphicon-share-alt:before {
  vertical-align: middle;
  position: absolute;
  top: -1px;
  left: 11px;
}
.subcourse-list-sharing-options i.fa.fa-file-text,
i.fa.fa-file-text.subcourse_file{
  font-size: 13px;
  color: #fff;
  vertical-align: middle;
  margin-left: 5px;
  background: #337ab7;
  height: 27px;
  width: 34px;
  text-align: center;
  line-height: 27px;
  top: 0;
  box-sizing: border-box;
  border: 1px solid #2e6da4;
  position: relative;
}

.subcourse-list-sharing-options i.fa.fa-plus,
i.fa.fa-file-text.subcourse_file{
  font-size: 13px;
  color: #fff;
  vertical-align: middle;
  margin-left: 5px;
  background: #ccc;
  height: 27px;
  width: 34px;
  text-align: center;
  line-height: 27px;
  top: 0;
  box-sizing: border-box;
  border: 1px solid #b9b9b9;
  position: relative;
}

.subcourse-list-title {
  float: left;
  font-size: 18px;
  color: var(--titleColor);
  margin-right: 10px;
  width: 10%;
  display: flex;
  align-items: center;
}

.subcourse-list-sharing-options {
  font-size: 13px;
  color: #666;
}
.subcourse-list-title i.glyphicon.glyphicon-list {
  color: #666;
  font-size: 13px;
  margin-right: 4px;
}
.caption.ng-binding.subcourse-list {
  display: flex;
  align-items: center;
}
.course-image-box {
  /* border: 4px solid #000;*/
  width: 200px;
  height: 200px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registrations-main {
  margin-top: 100px;
}
.course-view label.control-label.course-image-label {
  margin: 20px 0 0 0;
}
.course-view .course-divider {
  height: 40px;
}
.course-view p.page-header {
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.course-view .row.form-group {
  margin-bottom: 5px;
}
ul.course-options {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.course-options li:not(:last-child) {
  margin-bottom: 2px;
}

ul.course-options li {
  border: 1px solid #797979;
  padding: 5px;
}

ul.course-options li input {
  width: 55px;
  padding: 3px 5px;
}
.sidebar_page .mt-checkbox-list {
  display: flex;
  align-items: center;
  padding: 0;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.portlet.light .mt-checkbox-list.sidebar-list {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar_page label.ng-binding {
  margin: 0;
}
.sidebar_page .mt-checkbox > span {
  top: -2px;
}
.form .form-bordered .form-group .sidebar_page .help-block {
  flex: 100%;
  margin: 0;
}
.view_customer{
  float:left
}
.next-session {
  background-color: #0986BD;
  border-color: #0986BD;
}
/*mediaqueries*/
@media (max-width: 767px ) {
  .portlet.light {
    padding-left: 0;
    padding-right: 0;
  }

  .portlet.light .form .form-body {
    padding-top: 0
  }
}
a.customer-document {
  display: block;
  padding-bottom: 7px;
}

a.customer-document i.fa {
  margin-right: 8px;
  display: inline-table
}
.view_cutomer_form .form-horizontal .control-label {text-align: left !important;}

table .fileup {
  width: 35%;
}

table .filestatus {
  width: 15%;
}

.form .form-bordered .form-group {
  padding: 5px;
}

.form .form-bordered .form-group {
  padding: 5px;
}
.form .form-bordered .form-group > div{
  padding: 0;
}

.form .form-bordered .form-group .control-label {
  padding-top: 5px;
}

.showSweetAlert {
  max-height: 50% !important;
  overflow: auto !important;
}

.iconWithArrow {
  margin-top:-5px !important;
  border: none !important;
  margin-left: 0 !important;
}

.listScroll {
  max-height: 32em;
  overflow: auto;
}

.limitWithoutScrollPage {
  min-height: 39em;
}

/*
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 1000px;
}

::-webkit-scrollbar-thumb {
    border-radius: 1000px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.68);
}
*/
.grid-actions-context-icon {
  position: absolute;
  color: #e8e8e8;
  zoom: 120%;
  margin-top: 1px;
  border-radius: 50%;
}

.grid-actions-context-spacer {
  padding-left: 30px;
}

.noWrap {
  white-space: nowrap;
}


.list-for-autodoc  {
  list-style-type: none;
}

.pList:hover {
  color: #337ab7;
}

@media (max-width: 998px) {
  .iframeWebEmail {
    width: calc(100% + 40px);
    min-height: var(--content-min-height);
    height: calc(100vh - 50px);
    margin-top: -25px;
    margin-left: -20px;
    margin-bottom: -41px;
    margin-right: -20px;
  }
}

@media (min-width: 999px) {
  .iframeWebEmail {
    width: calc(100% + 40px);
    min-height: var(--content-min-height);
    height: calc(100vh - 50px);
    margin-top: -25px;
    margin-left: -20px;
    margin-bottom: -10px;
    margin-right: -20px;
  }
}

.page-footer {
  display: none;
  height: 0px !important;
  overflow: hidden;
  max-height: 0px !important;
}

.textLink {
  color: #337ab7;
  text-decoration: none;
}

.textLink:hover {
  color: #337ab7;
}

.page-sidebar .page-sidebar-menu>li.active2.open>a, .page-sidebar .page-sidebar-menu>li.active2>a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2.open>a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2>a, body > div.page-wrapper.ng-scope > layout-page > div > div.page-container > nav-sidebar > div > ul > li.nav-item.ng-scope.active2 > a:hover {
  background: #0986BD;
  border-top-color: transparent;
  color: #fff;
}

.page-sidebar .page-sidebar-menu>li.active2.open>a>.arrow.open:before, .page-sidebar .page-sidebar-menu>li.active2.open>a>.arrow:before, .page-sidebar .page-sidebar-menu>li.active2.open>a>i, .page-sidebar .page-sidebar-menu>li.active2>a>.arrow.open:before, .page-sidebar .page-sidebar-menu>li.active2>a>.arrow:before, .page-sidebar .page-sidebar-menu>li.active2>a>i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2.open>a>.arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2.open>a>.arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2.open>a>i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2>a>.arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2>a>.arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active2>a>i, body > div.page-wrapper.ng-scope > layout-page > div > div.page-container > nav-sidebar > div > ul > li.nav-item.ng-scope.active2 > a:hover > i, #box > nav-header > div > div.hor-menu.hor-menu-light.hidden-sm.hidden-xs > ul > li.ng-scope.classic-menu-dropdown.active > a > i, #box > nav-header > div > div.hor-menu.hor-menu-light.hidden-sm.hidden-xs > ul > li.ng-scope.classic-menu-dropdown.active > a:hover > i {
  color: #fff;
}

// fixes tinyMCE z-index  when opened inside a popup (edited)
.tox-tinymce-aux {
  z-index: 20002 !important;
}
