/***
Page content
***/

/* Page title */

.page-title {
	padding: 0px;
	font-size: 28px;
	letter-spacing: -1px;
	display: block;
	color: #666;
	margin: 0px 0px 15px 0px;
	font-weight: 300;

	/* subtitle */
	small {
		font-size: 14px;
		letter-spacing: 0px;
		font-weight: 300;
		color: #888;
	}

	.page-content-white &,
	.page-container-bg-solid & {
		color: #666;
		margin-bottom: 20px;
		margin-top: 20px;

		small {
			color: #666;
		}
	}

	.page-content-white & {
		margin: 25px 0;
		font-size: 24px;
	}
}

/* Page content */

.page-content {
	margin-top: 0px;
	padding: 0px;
	background-color: #fff;

	.page-container-bg-solid & {
		background: $page-content-solid-bg-color;
	}

	.page-full-width & {
		margin-left: 0px !important;
	}
}

@media (min-width: $screen-md-min) { /* 992px */

	/* Page content */
	.page-content-wrapper {
		float: left;
		width: 100%;

		.page-content {
			margin-left: $sidebar-width;
			margin-top: 0px;
			min-height: $page-content-min-height;
			padding: 25px 20px 10px 20px;

			&.no-min-height {
				min-height: auto;
			}

			.page-sidebar-fixed.page-sidebar-hover-on & {
				margin-left: $sidebar-collapsed-width;
			}

			.page-sidebar-reversed & {
				margin-left: 0 !important;
				margin-right: $sidebar-width !important;
			}

			.page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on & {
				margin-left: 0;
				margin-right: $sidebar-collapsed-width;
			}

			.page-sidebar-reversed.page-sidebar-closed  & {
				margin-left: 0 !important;
				margin-right: $sidebar-collapsed-width !important;
			}

			.page-sidebar-closed  & {
				margin-left: $sidebar-collapsed-width !important;
			}

			.page-sidebar-closed.page-sidebar-hide & {
				margin-left: 0 !important;
			}

			.page-sidebar-closed.page-sidebar-reversed.page-sidebar-hide & {
				margin-right: 0 !important;
			}

			.page-full-width & {
				margin-left: 0px !important;
			}
		}
	}
}

@media (max-width: $screen-sm-max) { /* 991px */
	// disable mobile vertical scroll
	html,
	body {
  		overflow-x: hidden;
	}

	/* Boxed page container  */
	.page-boxed > .container {
 		max-width: none !important;
 		margin: 0 !important;
 		padding: 0 !important;
 	}

 	/* Page content */
	.page-content-wrapper {
		.page-content {
			margin: 0px !important;
			padding: 20px 20px 20px 20px !important;
			min-height: 280px;
		}
	}

	// sidebar mobile menu offcanvas
	.page-sidebar-mobile-offcanvas {
		.page-wrapper {
			left: 0;
			transition: $general-transition;

			.page-header {
				transition: $general-transition;
			}
		}

		&.page-sidebar-mobile-offcanvas-open {
			overflow-x: hidden;
			transition: $general-transition;

			.page-wrapper {
				position: relative;
				left: $sidebar-width;
				transition: $general-transition;

				.page-header {
					transition: $general-transition;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { /*  768px & 991px */
	/* Boxed page container */
	.page-boxed > .container {
 		margin: auto !important;
 	}
}

@media (max-width: $screen-xs-max) { /* 767px */

	/* Page content */
	.page-content-wrapper {
		.page-content {
			padding: 20px 10px 10px 10px !important;
			overflow: hidden;

			/* Page title */
			.page-title {
				margin-bottom: 20px;
				font-size: 18px;

				small {
					font-size: 13px;
					padding-top: 3px;
				}
			}
		}
	}
}

@media (max-width: $screen-xs-min) { /* 480px */

	/* Dashboard date range panel */
	.page-content-wrapper {
		.page-content {
			.page-title {
				small {
					display: block;
					clear: both;
				}
			}
		}
	}
}
