$font-family-layout: "Open Sans",sans-serif;

/*header menu*/
nav-header {
    font-family: $font-family-layout;
}

/* breadcrumb */
.page-bar {
    &:after, &:before { /*force line breaks around it*/
        content: " ";
        display: table;
    }
    height: 45px;
    margin-bottom: 1rem;

    font-family: $font-family-layout;

    .page-breadcrumb {
        position: absolute; /*Allows positioning outside of the parent*/
        margin-left: -20px; /*cancels the margin of the .content outside div - for the border*/
        border-bottom: 1px solid #e9e8e8;
        padding-left: 25px; /*adds the same amount to reset the text position*/
        padding-top: 11px;
        padding-bottom: 11px;
        margin-top: -1rem;

        list-style-type: none;
        display: flex;
        gap: 1rem;
        font-size: 14px;
        color: var(--titleColor);
        font-weight: 400;
        margin-bottom: 2rem;
        width: calc(100% - 300px);

        i.fa-circle {
            font-size: 5px;
            margin: 0 5px;
            position: relative;
            opacity: 0.4;
        }

        li {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

    }
}



/* end new layout menus */

/* content */
.portlet.box.green {
    border: 1px solid #0986BD !important;
    border-top: 0;
    border-radius: 7px;

    .portlet-title {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;

        .actions {
            display: flex;
            gap: 1rem;
            align-self: center;
            justify-self: end;
        }

        .caption {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            font-size: 18px;
            line-height: 18px;
            align-self: center;
            justify-self: start;
        }
    }

    & > .portlet-title {
        background: #0986BD !important;
        color: #fff;
    }

    .nav.nav-tabs {
        position: relative;
        z-index: 1;
        margin-top: 1rem;
        border-bottom: none;

        .tabbable-line {
            li.active {
                a {
                    border-top: 4px solid #0986BD;
                    border-top-left-radius: 7px;
                    border-top-right-radius: 7px;
                    border-bottom: 3px solid #fff;
                    z-index: 3;
                }
            }
        }



    }

    .tab-content {
        margin: 0 1rem 1rem 0;
        //border: 1px solid #ddd;
        border-radius: 7px;
        z-index: 1;
        padding: 0.7rem;
    }

}

.portlet {
    border: 0;
}

.btn {
    font-size: 13px !important;
    padding: 0.5rem 1rem !important;;
    color: #666;

    i, em {
        color: #666;
    }
}

a.btn-primary,
a.primary,
a.btn-warning,
a.warning,
a.btn-danger,
a.danger,
a.btn-success,
a.success,
a.info,
a.blue,
a.btn-info,
.btn-primary,
.primary,
.btn-warning,
.warning,
.btn-danger,
.danger,
.btn-success,
.success,
.info,
.blue,
.btn.green,
.btn-info {
    color: #fff;

    i, em {
        color: #fff;
    }
}

.route-content {
    max-width: 100%;
    //overflow: auto;
}

@mixin header-menu() {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 12px;

    .active {
        background: #0986BD;
        max-height: 52px !important;

        a {
            color: #fff;
        }

        &:hover {
            max-height: 52px !important;
            background: #0986BD;
        }

        * {
            background: #0986BD;
            color: #fff;
            &:hover {
                background: #0986BD;
            }
        }
    }

    li {
        display: flex;
        flex-direction: row;
        padding: 1.5rem;
        cursor: pointer;
        align-items: center;

        .notification-badge {
            align-self: center;
            display: flex;
            align-items: center;
            border-radius: 4px !important;
            max-height: 20px;
            font-size: 9px;
            background-color: #FEFEFE !important;
            color: #0074A7 !important;;
            padding: 2px 3px;
            min-width: 15px;
            margin-left: 6px;
        }
    }

    li:hover {
        max-height: 52px !important;

        background: #0986BD;
    }

    a {
        max-height: 52px !important;

        color: #fff;
        text-decoration: none; /* no underline */
    }

    a:hover {
        color: #fff;
    }
}
@mixin settings-menu() {
    @include header-menu();

    .img-circle {
        height: 24px;
    }

    .dropdown-menu-items {
        display: none;
        position: absolute;
        top: 50px;
        list-style: none;

        left: auto;
        right: 0;
        background: #fff;
        border: 1px solid #eee;
        box-shadow: 5px 5px hsl(0deg 0% 40% / 10%);
        float: left;
        margin: 0;
        min-width: 175px;
        padding: 0;
        text-shadow: none;
        z-index: 1000;
        flex-direction: column;

        li {
            padding: 1rem 2rem;
            a {
                color: var(--titleColor);

                &:hover {
                    background: #0986BD;
                    color: #fff !important;
                }
            }

            &:hover {
                a {
                    color: #fff !important;
                }
            }


            &.active {
                a {
                    color: #fff !important;
                }
            }
        }
    }

    li:hover > ul {
        display:flex;
    }

    ul li {
        width: 170px;
        float: none;
        display: list-item;
        position: relative;
    }
    .dropdown-language {
        right: 130px;
    }

}

@media screen and (max-width: 990px) {
    .no-mobile,
    .nav-sidebar,
    .nav-sidebar-component{
        display: none;
    }

    .sd-title {
        padding: 1rem;
        font-weight: 600;
        color: #fff !important;
        font-size: 21px;
        text-decoration: none !important;
    }

    .header-mobile {
        width: 100%;
        display: flex;
        padding: 0 4rem 0 0;
        justify-content: space-between;
        background-color: #0074a7;

        .settings-menus {
            @include settings-menu();
        }
    }

    .mobile {
        position: absolute;
        width: 0;
        height: 100%;
        top: 0;
        overflow:hidden;
        background-color: #19222a;
        -webkit-transform: translateZ(0);
        visibility: visible;
        -webkit-backface-visibility: hidden;
        transition: 0.5s;

        .active {
            background: #0986BD !important;
            color: #fff !important;

            &:hover {
                background: #0986BD !important;
            }
        }

        .sidebar-nav {
            font-family: $font-family-layout;
            position: fixed;
            background-color: #19222a;
            height: 100%;
            font-weight: 400;
            font-size: 1.2em;
            overflow: auto;
            padding-bottom: 6em;
            z-index: 9;
            overflow: hidden;


            ul{
                list-style:none;
                display: block;
                padding: 0;
                margin: 0;

                li{
                    margin-left: 0;
                    padding-left: 0;
                    //min-width: 13em;
                    display:inline-block;
                    width: 100%;

                    a{
                        color: rgba(255,255,255,0.9);
                        font-size: 0.75em;
                        padding: 1.05em	1em;
                        position: relative;
                        display:block;

                        &:hover{
                            background-color: #0074a7;
                            transition: all 0.6s ease;
                        }
                    }
                    i{
                        font-size: 1.8em;
                        padding-right: 0.5em;
                        width: 9em;
                        display: inline;
                        vertical-align:middle;
                    }
                }
            }

            .nav-flyout {
                position: absolute;
                background-color: #0986BD;
                z-index: 9;
                left: 2.5em;
                top: 0;
                height: 100vh;
                transform: translateX(100%);
                transition: all 0.5s ease;
                width: 100%;

                & > li {
                    display: inline-flex;
                    align-items: center;
                }

                & > div {
                    display: none;
                    i {
                        display: none;
                        width: 34px;
                    }
                }

                a:hover {
                    background-color: #0074a7;
                }
            }

            ul > li:hover{
                background: #0986BD;
                .nav-flyout{
                    transform: translateX(0);
                    transition: all 0.5s ease;

                    div {
                        display: inherit;
                        i {
                            display: inherit;
                        }
                    }
                }
            }
        }

        a {
            text-decoration: none;
            transition:all 0.6s ease;

            display: flex !important;
            padding: 1rem !important;
            gap: 2rem;

            &:hover{
                transition: all 0.6s ease;
            }
        }
    }

    .collapse-button-mobile {
        position: absolute;
        top: 0;
        padding: 1.3rem 1rem 0 0;
        color: #fff;
        font-size: 21px;
        z-index: 10;
        right: 0;
    }

    .nav-header-component {
        display: none;
    }

    .nav-header-component {
        width: 0;
        transition: 0.3s;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 100vh;
        height: 100%;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        background-color: #111;
        overflow-x: hidden;
        padding-top: 60px;
        text-align: center;
        text-decoration: none;

    }

    .collapse-button-mobile:hover ~ .nav-header-component,
    .collapse-button-mobile:hover + .nav-header-component,
    .nav-header-component:hover {
        width: 300px;

        .mobile {
            width: 300px;
        }
    }
}

@media screen and (min-width: 990px) {
    .no-mobile {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 52px;
    }

    .mobile,
    .header-mobile {
        display: none;
    }

    .collapse-button-mobile {
        display: none;
    }

    .nav-header-component {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        background: #0074A7;

        .menus {
            @include header-menu();
            .active {
                background: #0986BD;

                a {
                    color: #fff;
                }

                &:hover {
                    background: #0986BD;
                }
            }
        }

        .settings-menus {
            @include settings-menu();
        }
    }

    .nav-sidebar-component {
        transition: 0.15s;
        background: #0074a7;
        width: 235px;
        height: 100%;
        font-size: 12px;

        &.collapse-all {
            width: 50px;
        }

        .expand-logo {
            height: 51px;
            font-size: 21px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            .sd-title {
                padding: 1rem;
                font-weight: 100;
                color: #FEFEFE;
                font-size: 21px;
                text-decoration: none;
                font-family: 'Open Sans';
            }
            .collapse-button {
                cursor: pointer;
                color: #fff;
            }
        }

        .nav-sidebar {
            display: flex;
            flex-direction: column;
            background: #0074a7;
            list-style-type: none;
            padding: 0;

            .nav-item {
                border: 1px solid #0074a7;
                color: #efefef;
                cursor: pointer;
                padding: 10px 10px 10px 15px;

                a {
                    font-family: 'Open Sans', sans-serif;
                    color: #efefef;
                    font-size: 12px;
                    font-weight: normal;
                    text-decoration: none;
                }

                i {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }

            .nav-group ~ .nav-item {
                padding-left: 3.2rem;
            }

            .side-bar-collapse {
                padding-left: 1.7rem !important;
            }

            .nav-item:hover {
                background: #0986BD;
            }

            .nav-group {
                padding: 1.1rem 20px;
                color: #fff;
                font-size: 12px;
                font-weight: 900;
            }

            .active {
                background: #0986BD;

                a {
                    color: #fff;
                }

                &:hover {
                    background: #0986BD;
                }

                * {
                    background: #0986BD;
                    color: #fff;
                    &:hover {
                        background: #0986BD;
                    }
                }
            }
        }

        .display-none {
            display: none;
        }

        .nav-item-name {
            transition: 0.5s;
        }

        .collapse {
            width: 50px;
        }

        .collapse.expand-logo,
        .side-bar-collapse {
            padding: 0 15px;
            position: relative;
        }

        .side-bar-collapse:hover {
            width: 250px;
            z-index: 1000;
            box-shadow: 5px 5px rgb(44 53 66 / 20%);

            .menu-name-hover {
                display: initial;
                margin-left: 1rem;
            }

        }

        .menu-name-hover {
            display: none;
            width: 300px;
        }
    }

    .header-and-content {
        .content {
            margin-top: 1rem;
            padding: 0 2rem !important;
            min-height: calc(100vh - 100px - 30px);

            .dataTable {
                width: 100% !important;
            }
        }
    }

    .header-and-content-sidebar-opened {
        //max-width: calc(100% - 235px);
    }

    .header-and-content-sidebar-closed {
        max-width: calc(100% - 50px) !important;
        .page-breadcrumb {
            width: calc(100% - 50px) !important;
        }
    }
}

.display-none {
    display: none !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: none !important;

    .filter-columns-groups {
        height: 48px;
    }
}

.more_students {
    color: red !important;
}

.mdc-label {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
}


.mat-light-blue-50 {
    color: #E1F5FE;
}

ng-container {
    display: contents;
}

.header-and-content-sidebar-closed {
    width: calc(100% - 60px);
}

.sd1 p {
    color: #333333;
}

.sd1.sd-angularjs,
.sd2 {
    .content {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        background: #fff;
        margin: 10px 25px 10px 25px;
        border-radius: 10px !important;
    }

    .page-bar {
        .page-breadcrumb {
            max-width: 100%;
            width: 100%;
            position: unset;
            margin-left: 0.5rem;
        }
    }

    .dropdown-toggle.tag {
        border-radius: 100px;
        color: #fff;

        .badge {
            border-radius: 100px;
            font-weight: normal;
        }
    }

    .action.mdc-button.mdc-button--raised {
        height: 30px !important;
        line-height: normal;
        font-size: 12px !important;
        padding: 0 1rem !important;
    }
}

.sd1.sd-angularjs:has(.header-and-content-sidebar-closed ) {
    padding-left: 5px !important;

    .page-breadcrumb {
        width: calc(100% - 60px);
    }

}

@media only screen and (max-width : 1200px) {
    .sd1.sd-angularjs:has(.header-and-content-sidebar-closed ) {
        padding-left: 50px !important;
    }
}

body:has(.sd2 .sd2-menu-is-open) {
    overflow: hidden;
}

.sd2-menu-is-open.panel .mat-mdc-card-content:has(.upstreams) {
    height: 100vh;
}

.sd1.sd-angularjs.is-popup {
    margin: 0 !important;
    padding: 1rem !important;
    width: calc(100vw - 2rem);
}

.header-documentation-mat-menu {
    width: 200px !important;
}

.responsive-navigation-footer {

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 70px;
    background: #fff;

    .new {
        position: absolute;
        right: 20px;
        bottom: 90px;
        border-radius: 50% !important;
        z-index: 1 !important;

        background: var(--buttonPrimaryBg);
        .fi.fi-rr-plus:before {
            zoom: 1.2 !important;
            line-height: unset !important;
            font-weight: 900 !important;
        }
    }
}

.responsive-navigation-footer-close-new:before {
    color: var(--textLink) !important;
}

.responsive-navigation-header-filters {
    height: 85vh;

    .mat-bottom-sheet-container {
        max-height: 85vh;
        padding: 0 !important;
        background: var(--systemBackgroundColor);
    }
}

.mobile-imdt-select {
    height: 85vh;

    .mat-bottom-sheet-container {
        max-height: 85vh;
        padding: 0 !important;
        background: var(--systemBackgroundColor);
    }
}

.sd1-route {
    .responsive-navigation-header {
        position: fixed !important;
    }
}

.responsive-navigation-modules {

    .sidemenu-link:not(.title:has(i)) {
        margin-bottom: -5px !important;
    }

    .title:has(i) {
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;
        gap: 7px !important;
        margin-left: -5px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
        color: var(--titleColor);
    }
}


body:has(.mdc-dialog--open) {
    overflow: hidden !important;
}
