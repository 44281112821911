@import "layouts/tagus-template/tagus.scss";

body {
    background: #f5f5f5 !important;
}

.sd1-route {
    .header-area:not(.sticky) {
        top: 0;
        position: fixed;
        width: calc(100% - 340px);
    }

    .header-area:has(.active) {
        top: 0;
        position: fixed;
        width: calc(100% - 110px);
    }

    .main-content {
        height: 0 !important;
        min-height: 0 !important;
    }
}

.sd2.sd-angularjs,
.sd1.sd-angularjs {
    padding-left: var(--aside-menu-width);
}

.sd2.sd-angularjs:has(.header-and-content-sidebar-closed) {
    padding-left: 5px !important;
    margin-right: -60px !important;
}

@media only screen and (max-width : 1199px) {

    .sd1.sd-angularjs:has(.header-and-content-sidebar-closed) {
        padding-left: 0 !important;
        margin-left: -60px !important;
    }

    .sd1.sd-angularjs {
        margin-top: 70px !important;

        padding-left: 0!important;
        .header-and-content-sidebar-closed,
        .header-and-content-sidebar-opened,
        .header-and-content {
            margin-top: 0 !important;
            width: 100% !important;
            height: calc(100dvh - 250px) !important;;
            max-height: calc(100dvh - 250px) !important;
            overflow: auto !important;
        }

        .page-breadcrumb {
            margin-left: 0 !important;
            width: calc(100% - 20px);
        }

    }

    .sd1-route {
        .header-area:not(.sticky) {
            width: calc(100% - 25px);
        }

        .header-area:has(.active) {
            width: calc(100% - 30px);
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .sd1.sd-angularjs {

        .header-and-content-sidebar-closed,
        .header-and-content-sidebar-opened,
        .header-and-content {
            width: 100% !important;
        }

        .page-breadcrumb {
            margin-left: 0 !important;
            width: calc(100% - 110px);
        }

        .header-and-content-sidebar-opened {
            .page-breadcrumb {
                width: calc(100% - 300px);
            }
        }
    }

    .sd1-route {
        .header-area:not(.sticky) {
            width: calc(100% - 280px);
        }

        .header-area:has(.active) {
            width: calc(100% - 110px);
        }
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .sd1.sd-angularjs {
        padding-left: var(--aside-menu-width);

        .content {
            padding:2rem!important;
        }

        .page-breadcrumb {
            margin-left: 0 !important;
            width: calc(100% - 320px);
        }

        .header-and-content-sidebar-opened {
            .page-breadcrumb {
                width: calc(100% - 320px);
            }
        }
    }

    .sd1-route {
        .header-area:not(.sticky) {
            width: calc(100% - 300px);
        }

        .header-area:has(.active) {
            width: calc(100% - 110px);
        }
    }
}

@media only screen and (min-width: 1600px) {
    .sd1.sd-angularjs {
        .content {
            padding: 0 2rem !important;
        }

        .page-breadcrumb {
            padding-left: 10px;
            margin-left: 0 !important;
            width: calc(100% - 380px);
        }
    }

    .sd1-route {
        .header-area:not(.sticky) {
            width: calc(100% - 340px);
        }

        .header-area:has(.active) {
            width: calc(100% - 110px);
        }
    }
}


.sd1 {
    @import "layouts/layout/layout.scss";
    @import "layouts/layout/custom.scss";
    @import "global/plugins.scss";
    @import "global/components.scss";
    //@import "layouts/layout/themes/darkblue.scss";

    table[datatable] {
        font-family: "Open Sans",sans-serif;
    }

    .dataTables_filter {
        float: right;
    }

    .dataTables_filter input {
        margin: 0px 5px 0px 5px;
    }

    .dataTables_paginate {
        float: right
    }

    .dataTables_paginate .pagination {
        li{
            padding: 0;
            margin: -1px;

            a{
                z-index: 0;
            }
        }
    }

    .nav-sidebar a{
        text-decoration: none;
    }

    .notification-badge {
        align-self: center;
        border-radius: 4px !important;
        max-height: 20px;
        font-size: 9px;
        background-color: #FEFEFE !important;
        color: #0074A7 !important;
        padding: 2px 3px;
        min-width: 15px;
        margin-left: 6px;
    }

    & {
        font-family: Open Sans,sans-serif;
    }

    .portlet-title {
        border-bottom: 0 !important;
        background-color: #0986BD;
    }

    .header-and-content-sidebar-closed {
        padding-left: 10px !important;
        width: 100%!important;
        max-width: 100% !important;

        .page-breadcrumb {
            width: calc(100% - 120px) !important;
        }

    }
}

.sd2 {

    .portlet-title {
        background-color: #0986BD;
        padding: 1rem 1rem 1rem 1.6rem;
    }
        /* warning */
    .mat-mdc-button-base.mat-warning,
    .mat-mdc-stroked-button.mat-warning,
    .mat-icon.mat-warning {
        color: #FBC02D;
    }

    .mat-mdc-button-base.mat-warning:hover,
    .mat-mdc-stroked-button.mat-warning:hover {
        background-color: #FFF9C4;
    }

    .mat-mdc-raised-button.mat-warning,
    .mat-mdc-flat-button.mat-warning,
    .mat-mdc-unelevated-button.mat-warning,
    .mat-mdc-fab.mat-warning,
    .mat-mdc-mini-fab.mat-warning {
        color: #f0fff3;
        background-color: #FBC02D;
    }

    .mat-mdc-icon-button.mat-warning {
        color:#FBC02D;
    }

    /* success */
    .mat-mdc-button-base.mat-success,
    .mat-mdc-stroked-button.mat-success,
    .mat-icon.mat-success {
        color: #4CAF50;
    }
    .mat-mdc-button-base.mat-success:hover,
    .mat-mdc-stroked-button.mat-success:hover {
        background-color: #2E7D32;
    }

    .mat-mdc-raised-button.mat-success,
    .mat-mdc-flat-button.mat-success,
    .mat-mdc-unelevated-button,
    .mat-mdc-fab.mat-success,
    .mat-mdc-mini-fab.mat-success {
        color: #f0fff3;
        background-color: #4CAF50;
    }

    .mat-mdc-icon-button.mat-success {
        color:#4CAF50;
    }

    /* danger */
    .mat-mdc-button-base.mat-danger,
    .mat-mdc-stroked-button.mat-danger,
    .mat-icon.mat-danger {
        color: #E53935;
    }
    .mat-mdc-button-base.mat-danger:hover,
    .mat-mdc-stroked-button.mat-danger:hover {
        background-color: #FFCDD2;
    }

    .mat-mdc-raised-button.mat-danger,
    .mat-mdc-flat-button.mat-danger,
    .mat-mdc-unelevated-button.mat-danger,
    .mat-mdc-fab.mat-danger,
    .mat-mdc-mini-fab.mat-danger {
        color: #f0fff3;
        background-color: #E53935;
    }

    .mat-mdc-icon-button.mat-danger {
        color:#E53935;
    }


}

/* sd-select expand panel (dropdown) - starts */
.sd-select-expand-panel {
    height: auto !important;
    max-height: 100% !important;
    min-width: 300px !important;

    .mat-mdc-optgroup-label {
        position: sticky;
        top: -10px;
        z-index: 1;
        background-color: #fff;
        font-weight: bold;
    }
}

.cdk-overlay-pane:has(.sd-select-expand-panel.has-many-options),
.mat-mdc-select-panel-above:has(.sd-select-expand-panel) {
    position: absolute;
    bottom: 15px !important;
}
/* sd-select expand panel (dropdown) - end */

/* extra-info */
.page-extra-info {
    background: #fff;

    .mdc-snackbar__surface {
        background: #fff !important;
        min-width: 220px !important;
    }

    .mdc-snackbar__actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

html {
    scroll-behavior: smooth;
}

@import "layouts/layout/layout_2.scss";

body:has(.new-angular-content.full-size-modules) {
    overflow: hidden;
}

.new-angular-content:has(.sd1-route) {
    .page-container {
        min-height: 180px !important;
        height: 180px !important;
    }

    .responsive-navigation-footer .new {
        display: none;
    }

    .responsive-navigation-header {
        position: fixed;
        top: 0;
        width: 100%;
    }

}

.full-size-modules {
    position: absolute;
    z-index: 99999;
    width: 100%;
    top: 0;
    .page-container {
        height: 100dvh !important;
    }

    .mat-drawer-container {
        height: 100dvh !important;
    }

}

.sweet-alert {
    h2 {
        font-size: 20px !important;
    }
}
