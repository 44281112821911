//## Main Layout CSS Builder

@import '../../global/_variables'; // global metronic framework variables
@import '../../global/components/_mixins'; // global metronic framework mixings

@import '_variables'; // theme level variables

@import 'layout/_print'; // print layout

@import 'layout/_header'; // page header
@import 'layout/_page-container'; // page main container
@import 'layout/_sidebar';  // page sidebar
@import 'layout/_footer';   // page footer

@import 'layout/_content'; // page consnde

@import 'layout/_quick-sidebar';  // quick sidebar toggler

@import '../quick-sidebar/_quick-sidebar';  // quick sidebar content
@import '../quick-sidebar/_quick-nav';  // quick navigation

/***
Page Loading
***/

.page-on-load {
	background: #fefefe;

	.page-header,
	.page-container,
	.page-footer,
	> .clearfix {
		display: none;
		transition: all 2s;
	}
}

.modal.fade .modal-dialog {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    transition: none !important;
    transition: none !important;
}
.modal.in .modal-dialog {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
}
