@media print {
	body {
    	background-color: #fff !important;
    	direction: ltr;
	}
  
	.page-bar {
		display: none; 
	}

	.page-sidebar-wrapper {
		display: none;
	}

	.page-quick-sidebar-wrapper {
		display: none;
	}

	.theme-panel {  
		display: none;    
	}

	.hidden-print {  
		display: none;
	}

	.page-footer {
		display: none;
	}

	.no-page-break {
		page-break-after: avoid;
	}

	.page-container {
		margin: 0px !important;
		padding: 0px !important;
	}

	.page-content {
		padding: 0 !important;
		min-height: 300px !important;
		padding: 0px 20px 20px !important;
		margin: 0 !important;
	}

	.table {
		th,
		td {
			text-align: left !important;
		}
	}
}

