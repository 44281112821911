.aside-menu-non-visible-area {
    position: fixed;
    width: 35px;
    height: 100%;
    z-index: 999;
}

.aside-menu-non-visible-area ~ .sidebar-area:hover,
.aside-menu-non-visible-area ~ .sidebar-area:has(*:hover),
.aside-menu-non-visible-area:hover ~ .sidebar-area {
    position: fixed;
    left: 0;
    z-index: 9999;
}

.sidebar-area {
    --fontSize: 14px;
    transition: left var(--transition);
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    position: fixed;
    height: 100vh;
    width: var(--aside-menu-width);
    z-index: 1024 !important;
    left: 0;
    top: 0;

    &.active {
        left: -300px;
    }

    .mat-expansion-panel-body {
        padding: 2px 0 3px !important;
    }

    .flaticon-menu-1 {
        margin-top: 3px !important;
    }

    .mat-expansion-panel .mat-expansion-panel-header {
        padding: {
            bottom: 11px !important;
        };
    }

    .mat-expansion-panel a,
    .mat-expansion-panel .mat-expansion-panel .mat-expansion-panel-header {
        padding-left: 56px !important;
    }

    .mat-expansion-panel .mat-expansion-panel a {
        padding-left: 80px !important;
    }

    .sidemenu-link {
        display: block;
        transition: .3s;
        position: relative;
        border-radius: 5px;
        margin-bottom: 4px;
        color: var(--titleColor);
        text-decoration: none;
        font: {
            size: 14px;
            weight: 500;
            family: var(--fontFamily);
        };
        padding: {
            bottom: 10px;
            left: 20px;
            top: 10px;
        };

        &:hover,
        &.active {
            background-color: var(--secondaryColor);

            .badge {
                background: var(--secondaryColor);
            }
            &::after {
                background-color: var(--secondaryColor);
            }
        }

        .item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: {
                right: 16px;
            }

            .title {
                display: flex;
                align-items: center;
                gap: 1rem;
            }

        }

        .badge {
            top: 50%;
            right: 32px;
            line-height: 18px;
            text-align: center;
            position: absolute;
            display: inline-block;
            transform: translateY(-50%);
            font: {
                size: 12px;
                weight: 500;
            };

            font-size: 11px;
            padding: 4px 8px;
            margin-left: 2px;
            border-radius: 50px;
            color: var(--titleColor);
            transition: var(--transition);
            background: var(--secondaryColor);
            &:hover {
                background-color: var(--secondaryColor);
            }
        }

    }

    .sidemenu-link:has(.menu-icon) {
        color: var(--titleColor);
    }

    .logo {
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        top: 0;
        padding: {
            top: 10px;
            left: 15px;
            right: 15px;
            bottom: 20px;
        };
        a {
            color: var(--titleColor);
            font: {
                family: var(--headingFontFamily);
                weight: 500;
                size: 24px;
            };
            span {
                margin-left: 10px;
            }
        }
    }
    .burger-menu {
        top: 30px;
        z-index: 3;
        opacity: 0;
        right: 15px;
        cursor: pointer;
        position: absolute;
        visibility: hidden;
        transition: var(--transition);

        span {
            height: 1px;
            width: 25px;
            margin: 6px 0;
            display: block;
            background: var(--titleColor);
            transition: var(--transition);
        }
    }
    .sidebar-menu {
        margin-top: 15px;
        .sub-title {
            color: var(--titleColor) !important;
            display: block;
            font-size: 14px;
            position: relative;
            padding-left: 20px;
            margin-bottom: 10px;
            text-transform: uppercase;

            &:not(:first-child) {
                margin-top: 10px;
            }

            &::before {
                left: 0;
                top: 50%;
                content: '';
                height: 1px;
                width: 12px;
                position: absolute;
                background: var(--titleColor);
                transform: translateY(-50%);
            }
        }
    }
    .sidebar-inner {
        padding: {
            top: 105px;
            left: 15px;
            right: 15px;
            bottom: 20px;
        };
    }
    .mat-accordion {
        .mat-expansion-panel {
            color: var(--titleColor);
            background-color: transparent;

            .mat-expansion-panel-header {
                transition: var(--transition);
                background-color: transparent;
                color: var(--titleColor);
                border-radius: 5px;
                position: relative;
                display: block;
                height: auto;
                font: {
                    size: 14px;
                    weight: 500;
                };
                padding: {
                    bottom: 14px;
                    right: 60px;
                    left: 37px;
                    top: 14px;
                };
                i {
                    transform: translateY(-50%);
                    position: absolute;
                    margin-top: 1px;
                    font-size: 15px;
                    line-height: 1;
                    left: 15px;
                    top: 55%;
                }
                .mat-content {
                    display: block;
                    flex-direction: unset;

                    .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
                        display: block;
                        margin-right: 0;
                        flex-grow: unset;
                        flex-basis: unset;
                        align-items: unset;
                        color: var(--titleColor);
                    }
                }
                &.mat-expanded {
                    .mat-expansion-indicator {
                        margin-top: -4px;

                        &::after {
                            transform: rotate(45deg);
                        }
                    }
                }
                .mat-expansion-indicator {
                    transform: translateY(-50%) !important;
                    position: absolute;
                    margin-top: -2px;
                    transition: .2s;
                    right: 15px;
                    top: 50%;

                    &::after {
                        transition: .2s;
                        transform: rotate(-45deg);
                    }
                }
                .badge {
                    top: 50%;
                    right: 32px;
                    line-height: 18px;
                    text-align: center;
                    position: absolute;
                    display: inline-block;
                    transform: translateY(-50%);
                    font: {
                        size: 12px;
                        weight: 500;
                    };

                    font-size: 11px;
                    padding: 4px 8px;
                    margin-left: 2px;
                    border-radius: 50px;
                    color: var(--titleColor);
                    transition: var(--transition);
                    background: var(--secondaryColor);
                }

                &:hover,
                &.active {
                    background-color: var(--secondaryColor);
                    .badge {
                        background: var(--secondaryColor);
                    }
                }
            }
            &.mat-expansion-panel-spacing {
                margin: 0;
            }
            &:not([class*=mat-elevation-z]) {
                box-shadow: unset;
            }
            &:first-of-type {
                border-radius: 0;
            }
            .mat-expansion-panel-content {
                display: block;
            }
            .mat-expansion-panel-body {
                padding: 8px 0 3px;
                gap: 1rem;

                .sidebar-sub-menu {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        top: 0;
                        bottom: 0;
                    };
                }
                .mat-expansion-panel-header {
                    font: {
                        size: 14px;
                        weight: 500;
                    };

                    padding: {
                        bottom: 10px;
                        left: 37px;
                        top: 10px;
                        right: 0;
                    };

                    .mat-content {
                        .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
                            color: var(--titleColor);
                            transition: var(--transition);
                        }
                    }
                    &:hover {
                        background-color: var(--mat-expansion-header-hover-state-layer-color) !important;
                        color: var(--titleColor) !important;

                        .mat-content {
                            .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
                                color: var(--titleColor) !important;
                            }
                        }
                        &::after {
                            color: var(--titleColor) !important;
                        }
                        .mat-expansion-indicator {
                            &::after {
                                color: var(--titleColor) !important;
                            }
                        }
                    }
                }
                .mat-expansion-panel-body {
                    padding-left: 20px;
                }
            }
            &:has(> .mat-expansion-panel-content > .mat-expansion-panel-body > .sidebar-sub-menu > .sidemenu-item > .sidemenu-link.active) {
                .mat-expansion-panel-header {
                    background-color: var(--secondaryColor);
                }
            }
        }
    }
}

.mat-content.mat-content-hide-toggle {
    margin-right: 0 !important;
}

.modules {

    .first {
        font-size: 1.1rem !important;
    }

    .main-items {

        .sidemenu-link:not(mat-expansion-panel .sidemenu-link) {
            margin: 0 !important;
            font-size: 1rem;
            font-weight: 500;

            .title {
                gap: 0.5rem;
            }
        }

        mat-panel-title {
            .title {
                font-size: 1rem;
            }
        }
    }

    .mat-expansion-panel-body {
        padding: 2px 0 3px !important;
    }

    .flaticon-menu-1 {
        margin-top: 3px !important;
    }

    & > mat-accordion {
        margin: 0 -20px;
    }

    .mat-expansion-panel .mat-expansion-panel-header {
        padding: {
            bottom: 11px !important;
        };
    }

    .mat-expansion-panel .mat-expansion-panel-header i:not(.arrow) {
        left: 10px !important;
    }

    .mat-expansion-panel .mat-expansion-panel-header i.arrow {
        right: 8px !important;
    }

    .mat-expansion-panel a {
        padding-left: 45px !important;
    }

    .mat-expansion-panel .mat-expansion-panel .mat-expansion-panel-header {
        padding: {
            left: 65px !important;
            top: 6px !important;
            bottom: 6px !important;
        }
        font-weight: 400;

        i:not(.arrow) {
            left: 40px !important;
        }
    }

    .mat-expansion-panel .mat-expansion-panel a {
        padding-left: 65px !important;
        margin-bottom: 5px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }

    }

    .mat-expansion-panel .mat-expansion-panel .mat-expansion-panel a {
        padding-left: 85px !important;
        margin-bottom: 5px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    .sidemenu-link {
        display: block;
        transition: .3s;
        position: relative;
        border-radius: 5px;
        margin-bottom: 4px;
        color: var(--titleColor);
        text-decoration: none;
        font: {
            size: 14px;
            weight: 400;
            family: var(--fontFamily);
        };
        padding: {
            bottom: 10px;
            left: 20px;
            top: 10px;
        };

        &:hover,
        &.active {
            background-color: var(--secondaryColor);

            .badge {
                background: var(--secondaryColor);
            }
            &::after {
                background-color: var(--secondaryColor);
            }
        }

        .item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: {
                right: 16px;
            }

            .title {
                display: flex;
                align-items: center;
                gap: 1rem;
            }

        }

        .badge {
            top: 50%;
            right: 32px;
            line-height: 18px;
            text-align: center;
            position: absolute;
            display: inline-block;
            transform: translateY(-50%);
            font: {
                size: 12px;
                weight: 500;
            };

            font-size: 11px;
            padding: 4px 8px;
            margin-left: 2px;
            border-radius: 50px;
            color: var(--titleColor);
            transition: var(--transition);
            background: rgba(117, 127, 239, 0.1);
            &:hover {
                background-color: var(--secondaryColor);
            }
        }

    }

    .sidemenu-link:has(.menu-icon) {
        color: var(--titleColor);
    }

    .logo {
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        top: 0;
        padding: {
            top: 10px;
            left: 15px;
            right: 15px;
            bottom: 20px;
        };
        a {
            color: var(--titleColor);
            font: {
                family: var(--headingFontFamily);
                weight: 500;
                size: 24px;
            };
            span {
                margin-left: 10px;
            }
        }
    }
    .burger-menu {
        top: 30px;
        z-index: 3;
        opacity: 0;
        right: 15px;
        cursor: pointer;
        position: absolute;
        visibility: hidden;
        transition: var(--transition);

        span {
            height: 1px;
            width: 25px;
            margin: 6px 0;
            display: block;
            background: var(--titleColor);
            transition: var(--transition);
        }
    }
    .sidebar-menu {
        margin-top: 15px;
        .sub-title {
            color: var(--titleColor) !important;
            display: block;
            font-size: 14px;
            position: relative;
            padding-left: 20px;
            margin-bottom: 10px;
            text-transform: uppercase;

            &:not(:first-child) {
                margin-top: 10px;
            }

            &::before {
                left: 0;
                top: 50%;
                content: '';
                height: 1px;
                width: 12px;
                position: absolute;
                background: var(--titleColor);
                transform: translateY(-50%);
            }
        }
    }
    .sidebar-inner {
        padding: {
            top: 105px;
            left: 15px;
            right: 15px;
            bottom: 20px;
        };
    }
    .mat-accordion {
        .mat-expansion-panel {
            color: var(--titleColor);
            background-color: transparent;

            .mat-expansion-panel-header {
                transition: var(--transition);
                background-color: transparent;
                color: var(--titleColor);
                border-radius: 5px;
                position: relative;
                display: block;
                height: auto;
                font: {
                    size: 14px;
                    weight: 500;
                };
                padding: {
                    bottom: 14px;
                    right: 60px;
                    left: 35px;
                    top: 14px;
                };
                i {
                    transform: translateY(-50%);
                    position: absolute;
                    margin-top: 1px;
                    font-size: 16px;
                    height: 16px;
                    width: 16px;
                    line-height: 1;
                    left: 15px;
                    top: 55%;
                }
                .mat-content {
                    display: block;
                    flex-direction: unset;

                    .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
                        display: block;
                        margin-right: 0;
                        flex-grow: unset;
                        flex-basis: unset;
                        align-items: unset;
                        color: var(--titleColor);
                    }
                }
                &.mat-expanded {
                    .mat-expansion-indicator {
                        margin-top: -4px;

                        &::after {
                            transform: rotate(45deg);
                        }
                    }
                }
                .mat-expansion-indicator {
                    transform: translateY(-50%) !important;
                    position: absolute;
                    margin-top: -2px;
                    transition: .2s;
                    right: 15px;
                    top: 50%;

                    &::after {
                        transition: .2s;
                        transform: rotate(-45deg);
                    }
                }
                .badge {
                    top: 50%;
                    right: 32px;
                    line-height: 18px;
                    text-align: center;
                    position: absolute;
                    display: inline-block;
                    transform: translateY(-50%);
                    font: {
                        size: 12px;
                        weight: 500;
                    };

                    font-size: 11px;
                    padding: 4px 8px;
                    margin-left: 2px;
                    border-radius: 50px;
                    color: var(--titleColor);
                    transition: var(--transition);
                    background: rgba(117, 127, 239, 0.1);
                }
                &:hover {
                    background-color: var(--secondaryColor);
                }
            }
            &.mat-expansion-panel-spacing {
                margin: 0;
            }
            &:not([class*=mat-elevation-z]) {
                box-shadow: unset;
            }
            &:first-of-type {
                border-radius: 0;
            }
            .mat-expansion-panel-content {
                display: block;
            }
            .mat-expansion-panel-body {
                padding: 8px 0 3px;
                gap: 1rem;

                .sidebar-sub-menu {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        top: 0;
                        bottom: 0;
                    };
                }
                .mat-expansion-panel-header {
                    font: {
                        size: 14px;
                        weight: 500;
                    };

                    padding: {
                        bottom: 10px;
                        left: 37px;
                        top: 10px;
                        right: 0;
                    };

                    .mat-content {
                        .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
                            color: var(--titleColor);
                            transition: var(--transition);
                        }
                    }
                    &:hover {
                        background-color: var(--mat-expansion-header-hover-state-layer-color) !important;
                        color: var(--titleColor) !important;

                        .mat-content {
                            .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
                                color: var(--titleColor) !important;
                            }
                        }
                        &::after {
                            color: var(--titleColor) !important;
                        }
                        .mat-expansion-indicator {
                            &::after {
                                color: var(--titleColor) !important;
                            }
                        }
                    }
                }
                .mat-expansion-panel-body {
                    padding-left: 20px;
                }
            }
            &:has(> .mat-expansion-panel-content > .mat-expansion-panel-body > .sidebar-sub-menu > .sidemenu-item > .sidemenu-link.active) {
                .mat-expansion-panel-header {
                    background-color: var(--secondaryColor);
                }
            }
        }
    }
}


/* Max width 767px */
@media only screen and (max-width : 767px) {

    .sidebar-area {
        z-index: 999 !important;
        left: -100%;

        .logo {
            padding: {
                top: 10px;
                bottom: 15px;
            };
            a {
                span {
                    margin-left: 15px;
                }
            }
        }
        .sidebar-menu {
            .sub-title {
                font-size: 13px;
            }
        }
        .burger-menu {
            top: 25px;
            right: 12px;

            .sidemenu-link {
                padding: {
                    left: 1rem;
                    bottom: 0;
                }

                .title {
                    span {
                        display: none;
                    }
                }
            }
        }
        .sidebar-inner {
            padding-top: 75px;
        }
        .mat-accordion {
            .mat-expansion-panel {
                .mat-expansion-panel-header {
                    font-size: 13.5px;
                    padding: {
                        bottom: 13px;
                        right: 50px;
                        left: 34px;
                        top: 13px;
                    };
                    i {
                        font-size: 14px;
                        left: 12px;
                    }
                    &.mat-expanded {
                        .mat-expansion-indicator {
                            margin-top: -2px;
                        }
                    }
                    .mat-expansion-indicator {
                        margin-top: -1px;
                        right: 12px;

                        &::after {
                            padding: 2px;
                        }
                    }
                    .badge {
                        right: 25px;
                        font-size: 11px;
                        padding: 0 7px 1px;
                    }
                }
                .mat-expansion-panel-body {
                    .sidebar-sub-menu {
                        .sidemenu-item {
                            .sidemenu-link {
                                font-size: 13.5px;
                                padding: {
                                    bottom: 9px;
                                    left: 30px;
                                    top: 9px;
                                };
                                &::after {
                                    left: 15px;
                                    width: 5px;
                                    height: 5px;
                                }
                            }
                        }
                    }
                    .mat-expansion-panel-header {
                        font-size: 13.5px;
                        padding: {
                            bottom: 9px;
                            left: 30px;
                            top: 9px;
                        };
                        &::after {
                            left: 15px;
                            width: 5px;
                            height: 5px;
                        }
                    }
                }
            }
            .sidebar-menu-link {
                font-size: 13.5px;
                padding: {
                    bottom: 13px;
                    right: 50px;
                    left: 34px;
                    top: 13px;
                };
                i {
                    font-size: 14px;
                    left: 12px;
                }
            }
        }
    }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width : 768px) and (max-width : 991px) {

    .sidebar-area {
        z-index: 999 !important;
        left: -100%;

        .burger-menu {
            .sidemenu-link {
                padding: {
                    left: 1rem;
                    bottom: 0;
                }

                .title {
                    span {
                        display: none;
                    }
                }
            }
        }
        .sidebar-inner {
            padding-top: 85px;
        }
    }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width : 992px) and (max-width : 1199px) {

    .sidebar-area {
        z-index: 999 !important;
        left: -100%;

        .burger-menu {
            .sidemenu-link {
                padding: {
                    left: 1rem;
                    bottom: 0;
                }

                .title {
                    span {
                        display: none;
                    }
                }
            }
        }
    }

}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {

}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {

    .sidebar-area {

        .logo {
            padding: {
                left: 25px;
                right: 25px;
            };
        }
        .burger-menu {
            right: 25px;
        }
        .sidebar-inner {
            padding: {
                left: 25px;
                right: 25px;
            };
        }
        .mat-accordion {
            .mat-expansion-panel {
                .mat-expansion-panel-header {
                    font: {
                        size: var(--fontSize);
                    };
                    padding: {
                        right: 75px;
                        left: 45px;
                    };
                    i {
                        left: 20px;
                        font-size: 1rem;
                    }
                    .mat-expansion-indicator {
                        right: 20px;
                    }
                    .badge {
                        right: 40px;
                    }
                }
                .mat-expansion-panel-body {
                    .sidebar-sub-menu {
                        .sidemenu-item {
                            .sidemenu-link {
                                font: {
                                    size: 14px;
                                };
                                padding: {
                                    left: 45px;
                                    top: 9px;
                                    bottom: 9px;
                                };
                                &::after {
                                    left: 26px;
                                }
                            }
                        }
                    }
                    .mat-expansion-panel-header {
                        font: {
                            size: 14px;
                        };
                        padding: {
                            left: 45px;
                            right: 0;
                            top: 6px;
                            bottom: 6px;
                        };
                        &::after {
                            left: 26px;
                        }
                    }
                }
            }
            .sidebar-menu-link {
                padding: {
                    right: 75px;
                    left: 45px;
                    top: 6px;
                    bottom: 6px;
                };
                font: {
                    size: var(--fontSize);
                };
                i {
                    left: 20px;
                    font-size: 1rem;
                }
            }
        }
    }

}

/* max width 1400px */
@media only screen and (max-width: 1400px) {
    .sidebar-area {
        .logo {
            span {
                font-size: 20px;
            }
        }
    }
}
